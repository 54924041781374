import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'tailwind.macro'
import config from '../../config/website'
import { colors } from '../../tailwind'
// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import Footer from '../components/Footer'

// Elements
import {
  BigBox,
  BulletList,
  ProjectInner,
  HeaderBigBox,
  ImageContainer,
  ImageContainerFull,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
  ImageCaption,
  ProjectCallout,
} from '../elements/Projects'
import { StyledLinkIn, StyledLinkOut } from '../elements/Links'
import { StatsContainer, Stat, BigNumber, Text } from '../elements/Stats'
import NextProject from '../components/NextProject'
import PollinateDemoVideo from '../videos/pollinate/pollinate-demo.mp4'

const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 84%;
  height: 65%;
  transform: translate(-44%, -69%);
`

const Video = styled.video`
  height: 100%;
`

const OrderedList = styled.ol`
  & > li {
    ${tw`mb-2`};
  }
`

const goalStats = [
  { number: 150, text: 'Participants' },
  { number: 3, text: 'Bay Area IBM locations represented' },
  { number: 3, text: 'Disciplines in attendance (Design, Development, PM)' },
]

const outcomeStats = [
  { number: 226, text: 'Registered participants' },
  { number: 5, text: 'All Bay Area IBM locations represented' },
  { number: 7, text: 'Disciplines in attendance (technical sales, marketing, content, etc)' },
]

const theme = {
  menu: colors.white,
  menuHover: colors.black,
  accent: colors['am-yellow'],
  ally: colors['am-yellow-ally'],
}

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/pollinate.jpg`

const Page = ({ data }) => {
  const images = {}
  data.pollinate.images.forEach(({ name, childImageSharp }) => {
    images[name] = childImageSharp
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Helmet>
          <meta property="og:image" content={image} />
        </Helmet>
        <Menu color={colors.white} />
        <HeaderBigBox>
          <ProjectInner>
            <Img fluid={images['pollinate-header'].fluid} />
          </ProjectInner>
        </HeaderBigBox>
        <ProjectInner>
          <ProjectHeader>
            <ProjectDate>Summer 2019 and Winter 2020</ProjectDate>
            <ProjectTitle>IBM Pollinate</ProjectTitle>
            <ProjectDescription>Bringing culture and creativity to IBMers.</ProjectDescription>
            <TagList tags={['Culture', 'Event Design', 'Experience Design', 'Public Speaking']} />
          </ProjectHeader>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Inception</ProjectSectionTitle>
              <ProjectSectionText>
                While working on{' '}
                <StyledLinkIn to="/search" bg={colors['am-green']} color={colors['am-green-ally']}>
                  platform-wide search
                </StyledLinkIn>{' '}
                my manager noticed I was itching for more to do. Outside of my project work, I had begun to earn the
                title of “Culture Queen” by planning many bonding activities. Luckily, she noticed how pumped I was
                while doing that and asked me to create something even bigger: to impact culture at a grand scale.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="2.5rem">
              <ProjectCallout>
                <p>
                  “Create an event that will bring together Developers, Designers, and Project Managers to make the IBM
                  Silicon Valley Lab a hub for design.”
                </p>
                <div style={{ fontSize: 16 }}>- Ana Manrique. Design Manager</div>
              </ProjectCallout>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>My contribution</ProjectSectionTitle>
              <ProjectSectionText>
                I created and spearheaded a semi-annual conference called IBM Pollinate. I recruited a committee of four
                IBMers to plan the first conference for August 7th, 2019 . My tasks included creating a mission,
                establishing a budget, delegating tasks to the committee, securing event space, and marketing a new
                event.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['pollinate-event'].fluid} />
          <ProjectInner>
            <ImageCaption>200+ IBMers gather for the keynote talk</ImageCaption>
          </ProjectInner>
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>New responsibilities</ProjectSectionTitle>
              <ProjectSectionText>
                While continuing to balance my product work, I met with the committee once or twice monthly for six
                months. Together we ran into a few challenges:
              </ProjectSectionText>
              <BulletList>
                <li>
                  <strong>Establishing a budget:</strong> How much would it cost? Where would the money come from?
                  Luckily, I met an event coordinator at IBM and together we created and secured a 9k budget.
                </li>
                <li>
                  <strong>Fully remote team:</strong> The committee only met in person once in a six month period before
                  the event. We learned how to balance meeting cadence and expectations while continuing our full time
                  design jobs.{' '}
                </li>
                <li>
                  <strong>Marketing and spreading the word:</strong> The five Bay Area studios had not seen an event
                  like this before. We had to come up with a name and mission that would quickly convince IBMers to get
                  involved.
                </li>
              </BulletList>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection style={{ marginBottom: '3rem', width: '100%' }}>
              <ImageContainer>
                <Img fluid={images['pollinate-logo'].fluid} style={{ width: '270px', marginBottom: '3rem' }} />
                <Img fluid={images['pollinate-branding'].fluid} />
              </ImageContainer>
              <ImageCaption>Original IBM Pollinate branding by Mihir Chauhan</ImageCaption>
            </ProjectSection>
            <ProjectSection>
              <ProjectSectionTitle>Creating a mission</ProjectSectionTitle>
              <ProjectSectionText>
                Our committee came up with the name IBM Pollinate that holds strong for two reasons:
              </ProjectSectionText>
              <OrderedList>
                <li>
                  We IBMers have a bee in{' '}
                  <StyledLinkOut
                    href="https://www.ibm.com/design/language/ibm-logos/rebus/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    our logo
                  </StyledLinkOut>
                  .
                </li>
                <li>Pollinate represents the sharing of ideas in a creative way.</li>
              </OrderedList>
              <ProjectSectionText>Here is the message we presented:</ProjectSectionText>
              <ProjectCallout>
                IBM Pollinate, a full-day IBM-only event, in which Developers, Designers, and Offering Managers (Product
                Managers) held sessions to share a creative skill or craft of their choice to an audience of their
                peers.
              </ProjectCallout>
              <ProjectSectionText>
                The event would tap into our collective creative expertise and provided an opportunity for our team to
                come together in the true sense of community.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Achieving and succeeding</ProjectSectionTitle>
              <ProjectSectionText>
                Our four committee members split up several responsibilities: branding, recruiting volunteers and
                speakers, creating a registration website, and ordering food and swag. The conference had one keynote
                speaker and 24 IBM led sessions that each ranged in topics, structure, and length. Examples of session
                topics include: demystifying data science, guitar and UX design, power of personal narrative, switching
                careers, visual note taking, accessibility, animations made easy, introspection and Kazien.{' '}
              </ProjectSectionText>
              <ProjectSectionText>I’m very proud to share the outcome of the first conference:</ProjectSectionText>
            </ProjectSection>
            <ProjectSection />
            <ProjectSectionTitle style={{ margin: 0 }}>Goals</ProjectSectionTitle>
            <StatsContainer style={{ marginBottom: '2rem' }}>
              {goalStats.map(({ number, text }) => (
                <Stat>
                  <div>
                    <BigNumber style={{ textDecoration: 'line-through' }}>{number}</BigNumber>
                  </div>
                  <Text>{text}</Text>
                </Stat>
              ))}
            </StatsContainer>
            <ProjectSectionTitle style={{ margin: 0 }}>Outcomes</ProjectSectionTitle>
            <StatsContainer>
              {outcomeStats.map(({ number, text }) => (
                <Stat>
                  <div>
                    <BigNumber>{number}</BigNumber>
                  </div>
                  <Text>{text}</Text>
                </Stat>
              ))}
            </StatsContainer>
          </ProjectRow>
          <ProjectRow>
            <ImageContainer style={{ width: '100%' }}>
              <iframe
                title="Pollinate 2019"
                width="100%"
                src="https://www.youtube.com/embed/lLTcD1zGciM"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ margin: 'auto', minHeight: '350px' }}
              ></iframe>
              <ImageCaption>Video by Raymond Suarez</ImageCaption>
            </ImageContainer>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Expanding from a conference to a culture</ProjectSectionTitle>
              <ProjectSectionText>
                After the conference, we expanded our resources and network due to the demand coming from IBMers. They
                wanted more! We created an internal website and encouraged IBMers to host mini talks between the
                semi-annual conference. The committee grew from 4 to 9 in order to keep up with the new responsibilities
                and mentor new members to take on primary roles once they felt up to speed. I increased the budget from
                9k to 16k so we could go bigger and badder for the second conference.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <BigBox>
          <ProjectInner>
            <ImageContainer style={{ margin: '4rem auto' }}>
              <Img fluid={images['pollinate-imac'].fluid} />
              <VideoContainer>
                <Video autoPlay muted loop controls playsinline>
                  <track kind="captions" />
                  <source src={PollinateDemoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </Video>
              </VideoContainer>
            </ImageContainer>
          </ProjectInner>
        </BigBox>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Planning Winter Pollinate 2020</ProjectSectionTitle>
              <ProjectSectionText>
                Our committee was more agile the second time around. I mapped out a 6 month plan to help committee
                members communicate with their managers and understand when their priorities would pick up.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['pollinate-planning'].fluid} />
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Adapting our efforts</ProjectSectionTitle>
              <ProjectSectionText>
                On March 6th, five days before the Spring IBM Pollinate, we decided to cancel the conference due to
                COVID-19. My committee was devastated, and so was I. After a few weeks of brainstorming we took the
                conference virtual. The speakers signed up for slots over a 2.5 month period on Friday afternoons. As
                participants began to tune in we saw engagement of 70-100 IBMers attending every talk. This allowed
                speakers to have bigger audiences than the first conference.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>A new sharing culture at IBM</ProjectSectionTitle>
              <ProjectSectionText>
                I set up the committee to continually have a changing mix of experienced and new members so that this
                effort will stay alive for years to come. The Pollinate message was powerful enough to encourage IBMers
                outside of our committee to create a mini site-specific Pollinate in for IBMers in Emeryville, and
                regular “buzz talks” that happen anywhere.
              </ProjectSectionText>
              <ProjectSectionText>
                Company culture proves to be one of my passions, and I am so glad I had a space to affect change at IBM.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="2.5rem">
              <ProjectCallout>
                <p>
                  “IBM Pollinate was the best IBM event I’ve attended. I learned concrete skills, met people with whom I
                  plan to collaborate in the future, and felt inspired to return to my day job knowing that I have a
                  whole community of developers, OM’s, and designers.”
                </p>
                <div style={{ fontSize: 16 }}>- Product Manager</div>
              </ProjectCallout>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ImageContainer>
              <Img fluid={images['pollinate-committee'].fluid} />
              <ImageCaption>The only in-person committee meeting for IBM Pollinate 2020.</ImageCaption>
            </ImageContainer>
          </ProjectRow>
        </ProjectInner>
        <NextProject to="/search" bg={colors['am-green']} color={colors['am-green-ally']}>
          Platform-side search
        </NextProject>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  {
    pollinate: allFile(filter: { relativePath: { glob: "pollinate/**/*" } }) {
      images: nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
