import tw from 'tailwind.macro'
import styled from 'styled-components'
import { screens } from '../../tailwind'

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  text-align: center;
  color: ${props => props.theme.ally};
`

export const Stat = styled.div`
  display: grid;
  grid-template-rows: repeat(2, minmax(100px, auto));
`

export const BigNumber = styled.span`
  font-size: 5rem;
  font-weight: 800;
  color: ${props => props.theme.accent};

  @media (min-width: ${screens.lg}) {
    font-size: 7rem;
  }
`

export const Unit = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 5rem;
  transform: translate(10%, -30%);
  @media (min-width: ${screens.lg}) {
    font-size: 4rem;
    line-height: 7rem;
    transform: translate(10%, -20%);
  }
`

export const Text = styled.div`
  ${tw`mt-4`}
  font-size: 1rem;
  @media (min-width: ${screens.lg}) {
    font-size: 1.25rem;
  }
`
